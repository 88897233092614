<template lang="pug">
include ../../../configs/template
div.row.text-left
  div.col-12
    +data-info('number', 'statement.number')
  div.col-12
    +data-info('qualification', 'qualificationCourse')
  div.col-12
    +data-info('nameInstitution', 'institution')
  div.col-sm-12.col-md-4
    +data-info('meetingDate', 'getDateFormat(statement.date_meeting)')
  div.col-sm-12.col-md-4
    +data-info('createDate', 'statement.created_at')
  div.col-sm-12.col-md-4
    +data-info('dateModified', 'statement.modified_at')
  div.col-sm-12.col-md-4
    +data-info('paymentPurpose', 'statement.requisites.payment_due')
  div.col-sm-12.col-md-4
    +data-info('bank', 'statement.requisites.bank')
  div.col-sm-12.col-md-4
    +data-info('amount', 'priceWithCommission')
  div(v-if="statement.is_international").col-sm-12.col-md-4
    +data-info('havCopyinternational', 'statement.is_international ? $t("yes") : $t("no")')
  div.col-12
    b {{ $t('payment') }}:
    v-btn(
      v-if="statement.institution.can_pay_platon && statement.requisites.amount && !statement.is_payed && checkAccess('crewingManager')"
      @click="createPayment") {{ $t('pay') }}
    span(v-else) {{ statement.is_payed ? $t('isPayed') : $t('notPayed') }}
  div.col-12
    +data-info('typeLearning', 'statement.is_for_online_learning ? $t("onlineLearning") : $t("offlineLearning")')
  div.col-12
    +data-info('status', 'statusDocumentName')(:class="getStatus(statement.status_document)")
  div(v-if="statement.comments?.length").col-sm-12.p-0
    div.text-left.col-12
      v-divider
      h5.p-0.m-0 {{ statement.status_document === STATUSES_STATEMENT_CONSTANTS.SAILOR.ETI.IN_PROCESSING ? $t('rejectionReasonPayment') : $t('rejectionReasonStatement')}}:
      v-divider
      div(v-for="(comment, index) in statement.comments" :key="comment.id").ml-4
        div(v-if="comment.additional_info.reason")
          +data-info('rejectionReasonStatement','getDirectoryObject({ id: comment.additional_info.reason, value: "rejectionReasons" })[nameLang]')
        div.pt-2.py-3
          +data-info('createdBy', 'comment.created_by.name')
        div.pt-2.py-3
          +data-info('dateCreate', 'new Date(comment.created_by.date).toLocaleString()')
        div.pt-2.py-3
          +data-info('reasonComment', 'comment.comment')
        v-divider(v-if="index !== statement.comments.length-1")
  div.col-12
    v-btn(
      v-if="checkAccess('backoffice') && statement.can_create_exam"
      :loading="isLoading"
      color="primary"
      @click="handleToTransferOnExam"
      ) {{$t('transferForExam')}}
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { getDateFormat, getStatus, getDirectoryObject } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { OPTIONS } from '@/store'
import { STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'
export default {
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      STATUSES_STATEMENT_CONSTANTS,
      getDateFormat,
      checkAccess,
      getStatus,
      rejectStatusId: 62,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters(['rejectionReasonByID', 'getDirectoryObject']),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    verificationRefusalReason () {
      return this.comments.find(item => item.additional_info.cancel_reason)
    },
    verificationRefusalReasonName () {
      return this.rejectionReasonByID(this.verificationRefusalReason.additional_info.reason)[this.nameLang]
    },
    statusDocumentName () {
      return getDirectoryObject({ value: 'statuses', id: this.statement.status_document })[this.nameLang]
    },
    priceWithCommission () {
      if (checkAccess('certificationStatement', 'payment', this.statement)) {
        return this.statement.requisites.amount + (this.statement.requisites.amount * 0.04)
      } else return this.statement.requisites.amount
    },
    qualificationCourse () {
      return getDirectoryObject({ value: 'courses', id: this.statement.course })[this.nameLang]
    },
    institution () {
      return getDirectoryObject({ value: 'educationTraining', id: this.statement.institution })[this.nameLang]
    }
  },
  methods: {
    ...mapActions(['getTokenAndIdExamEti']),
    createPayment () {
      const params = new URLSearchParams({ callback_url: window.location.href })
      fetch(`${process.env.VUE_APP_API}payments/platon/statement_certificate/${this.statement.id}/?${params}`, OPTIONS)
        .then(response => {
          response.text().then(html => {
            document.getElementById('app').innerHTML = html
            document.getElementById('pay').click()
          })
        })
    },
    async handleToTransferOnExam () {
      this.isLoading = true
      const { id, documentID } = this.$route.params
      await this.getTokenAndIdExamEti({
        id,
        documentID,
        domain: this.getDirectoryObject({ id: this.statement.institution, value: 'educationTraining' }).personal_cabinet_host_domain
      })
      this.isLoading = false
    }
  }
}
</script>
